import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, publishReplay, refCount } from 'rxjs/operators';
import { CookieJar } from '../../shared/cookie-jar';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private sessionCache: any = null;
  constructor(private http: HttpClient, private cookieJar: CookieJar) {}

  hitSession(clearCache: boolean = false): Observable<boolean> {
    if (clearCache) {
      this.sessionCache = null;
    }

    if (!this.sessionCache) {
      const options = { withCredentials: true };
      this.sessionCache = this.http.post<any>('/session/5979', null, options).pipe(
        map(data => {
          const keys = Object.keys(data);
          const cookieName = keys[0];
          const token = data[cookieName];
          const sessionCookie = this.cookieJar.get(cookieName);

          if (!sessionCookie || sessionCookie !== token) {
            this.cookieJar.set(cookieName, token, {
              path: '/',
              httpOnly: false,
              secure: false,
              expires: new Date(new Date().getTime() + 10 * 365 * 24 * 60 * 60 * 1000),
            });
          }

          return true;
        }),
        publishReplay(1),
        refCount()
      );
    }
    return this.sessionCache;
  }
}
