import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { StartupService } from './core/services/startup.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private readonly title = 'Guerlain Spa';

  constructor(
    private router: Router,
    private titleService: Title,
    private startup: StartupService
  ) {}

  ngOnInit() {
    this.titleService.setTitle(this.title);

    // If there is no startup data received (maybe an error!)
    // navigate to error route
    if (!this.startup.startupData) {
      this.router.navigate(['error'], { replaceUrl: true });
    }
  }
}
