import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpClient,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';
import { Utils } from '@app/core/services/utils.service';
import { tap } from 'rxjs/internal/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs/Rx';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private auth: AuthService,
    private router: Router,
    private toastr: ToastrService,
    private utils: Utils
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(evt => {
        const currentUser = this.utils.getItem('currentUser');

        if (currentUser && evt instanceof HttpResponse) {
          if (
            evt.body &&
            evt.status === 200 &&
            !evt.body.IsSuccess &&
            evt.body.ErrorCode === 1000
          ) {
            // auto logout if evt.body.ErrorCode === 1000 Invalid access token

            const uidItem = this.auth.getUid();

            // if uid exists then auto login else logout
            if (uidItem) {
              this.autoLogin(uidItem.email, uidItem.uid);
            } else {
              this.resetUserAndRedirect();
            }
          }
        }
      }),
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';

        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
        } else {
          // server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }

        // if (error.status === 401) {
        //     auto logout if 401 response returned from api
        // this.auth.logout();
        // location.reload(true);
        // }

        return throwError(errorMessage);
      }) as any
    );
  }

  autoLogin(email, uid) {
    this.auth.login(email, uid).subscribe(
      res => {
        if (res.error == null) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          this.utils.setItem('currentUser', res);
          this.auth.setCurrentUserValue(res);

          // emit an event
          // to do something on autologin success
          this.utils.onAutoLogin.next(true);
        } else {
          this.resetUserAndRedirect();
          this.toastr.error(this.utils.messages.auth.autoLoginError);
        }
      },
      err => {}
    );
  }

  resetUserAndRedirect() {
    this.utils.removeItem('currentUser');
    this.utils.removeItem('email');
    this.utils.removeItem('uid');

    this.utils.resetLocalData();
    this.auth.setCurrentUserNull();
    this.router.navigate(['/']);
  }
}
