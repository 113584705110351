import { Component, OnInit, Input } from '@angular/core';
import { ModalManagementService } from '@app/core/services/modal-management.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '@app/core/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Utils } from '../../core/services/utils.service';
import { ToastrService } from 'ngx-toastr';
import { Email } from '@namespaces/validators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  passwordToggle = false;
  isLoggedIn = false;
  currentRoute: string = this.router.url;
  @Input() goToRoute = '/';

  isRememberMe: boolean;

  processing = false;

  constructor(
    private modal: ModalManagementService,
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private utils: Utils,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.isRememberMe = this.utils.getItem('isRememberMe');

    this.loginForm = this.formBuilder.group({
      email: [
        this.setRememberMe('email'),
        [Validators.required, Validators.pattern(Email.Pattern)],
      ],
      password: ['', Validators.required],
    });

    // get query params
    this.route.queryParamMap.subscribe(queryParams => {
      if (queryParams.get('goToRoute')) {
        this.goToRoute = queryParams.get('goToRoute');
      }
    });
  }

  setRememberMe(key) {
    if (this.isRememberMe && localStorage.getItem(key) !== null) {
      return localStorage.getItem(key);
    } else {
      return '';
    }
  }

  onSubmit() {
    this.submitted = true;

    if (this.loginForm.valid) {
      this.processing = true;
      this.auth.login(this.loginControl.email.value, this.loginControl.password.value).subscribe(
        res => {
          if (res.error == null) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            this.utils.setItem('currentUser', res);
            this.auth.saveUid(this.loginControl.password.value, this.loginControl.email.value);

            this.auth.setCurrentUserValue(res);
            this.dismissModal();
            this.router.navigate([this.goToRoute]);
          } else {
            this.toastr.error(this.utils.messages.auth.loginError);
          }
          this.processing = false;
        },
        err => {
          this.processing = false;
        }
      );

      if (this.isRememberMe) {
        localStorage.setItem('email', this.loginControl.email.value);
        localStorage.setItem('isRememberMe', JSON.stringify(this.isRememberMe));
      } else {
        localStorage.removeItem('email');
        localStorage.removeItem('isRememberMe');
      }
    }
  }

  dismissModal() {
    this.modal.modalClose();
  }

  openModal(content) {
    this.dismissModal();
    this.modal.modalOpen(content);
  }

  get loginControl() {
    return this.loginForm.controls;
  }

  showPassword() {
    this.passwordToggle = !this.passwordToggle;
  }
}
