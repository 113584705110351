import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalManagementService } from '../core/services/modal-management.service';
import { Router } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import { Utils } from '../core/services/utils.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-layout-result',
  templateUrl: './layout-result.component.html',
  styleUrls: ['./layout-result.component.scss'],
})
export class LayoutResultComponent implements OnInit, OnDestroy {
  _title;

  currentUrl: any;
  showMenuMobile = false;

  isLoggedIn = false;
  usernameDisplay: string;
  routerSubscription;
  authSubscription;

  constructor(
    private modal: ModalManagementService,
    private router: Router,
    private utils: Utils,
    private auth: AuthService,
    private toastr: ToastrService,
    private ngxLoader: NgxUiLoaderService
  ) {
    this.routerSubscription = this.router.events.subscribe((event: any) => {
      setTimeout(() => {
        this.currentUrl = this.router.url;
      }, 100);
    });
  }

  ngOnInit() {
    // get user values from current user observable after login to show in header
    this.authSubscription = this.auth.currentUser.subscribe(value => {
      if (value) {
        this.isLoggedIn = true;
        if (value.Customer) {
          this.usernameDisplay =
            value.Customer.Customer.FirstName.charAt(0) +
            value.Customer.Customer.LastName.charAt(0);
        }
      } else {
        // if page is refreshed and currentUser observers value is null get currentUser from local storage
        const currentUser = this.utils.getItem('currentUser');

        if (currentUser) {
          this.isLoggedIn = true;
          this.usernameDisplay =
            currentUser.Customer.Customer.FirstName.charAt(0) +
            currentUser.Customer.Customer.LastName.charAt(0);
        } else {
          // if currentUser observers value is null and there is no currentUser in Localstorage
          this.isLoggedIn = false;
          this.usernameDisplay = null;
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }

    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }

  toggleMenuMobile() {
    this.showMenuMobile = !this.showMenuMobile;
  }

  get title(): string {
    return this._title;
  }

  openModal(content) {
    this.modal.modalOpen(content);
  }

  dismissModal() {
    this.modal.modalClose();
  }

  logout() {
    this.showMenuMobile = false;
    this.ngxLoader.start();

    this.auth.logout().subscribe(res => {
      if (res.IsSuccess) {
        this.utils.removeItem('currentUser');

        if (this.currentUrl !== '/') {
          this.utils.resetLocalData();
        }

        this.auth.setCurrentUserNull();
        this.router.navigate(['/']);
        this.ngxLoader.stop();
      } else {
        this.utils.removeItem('currentUser');
        this.auth.setCurrentUserNull();
        this.ngxLoader.stop();
        this.router.navigate(['/']);
      }
    });
  }
}
