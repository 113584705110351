import { Component, OnInit, ViewChild, TemplateRef, Input } from '@angular/core';
import { AuthService } from '@app/core/services/auth.service';
import { ModalManagementService } from '@app/core/services/modal-management.service';
import { Utils } from '@app/core/services/utils.service';
import { FormGroup, FormBuilder, Validators, ValidationErrors } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';

declare const moment: any;

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  @Input() goToRoute = '/';
  @ViewChild('signup', { static: false }) signup: TemplateRef<any>;

  constructor(
    private modal: ModalManagementService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private toastr: ToastrService,
    private utils: Utils
  ) {}

  profileForm: FormGroup;
  submitted = false;

  email;
  password;
  cellphone;

  processing = false;

  readonly birthMonths = moment.months();
  readonly birthDays = Array(31)
    .fill(0)
    .map((item, index) => index + 1);

  readonly staffGenderPreferences = environment.booker.customFields.staffGenderPreferenceValues;
  readonly staffGenderPreferenceIds = Object.keys(this.staffGenderPreferences);

  ngOnInit() {
    this.profileForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      birthMonth: ['', Validators.required],
      birthDay: ['', Validators.required],
      gender: ['0', Validators.required],
      sms: [''],
      email: [''],
      staffGenderPreference: [this.staffGenderPreferences[this.staffGenderPreferenceIds[0]].id],
      allergies: [''],
    });

    this.email = this.auth.signUpData.Email;
    this.password = this.auth.signUpData.Password;
    this.cellphone = this.auth.signUpData.CellPhone;

    if (this.auth.signUpData.FirstName) {
      this.profileForm.controls.firstName.patchValue(this.auth.signUpData.FirstName);
    }
    if (this.auth.signUpData.LastName) {
      this.profileForm.controls.lastName.patchValue(this.auth.signUpData.LastName);
    }
    if (this.auth.signUpData.GenderID) {
      this.profileForm.controls.gender.patchValue(this.auth.signUpData.GenderID);
    }
    if (this.auth.signUpData.birthday) {
      this.profileForm.controls.birthMonth = this.auth.signUpData.birthday.format('M');
      this.profileForm.controls.birthDay = this.auth.signUpData.birthday.format('D');
    }

    if (this.auth.signUpData.AllowReceiveEmails) {
      this.profileForm.controls.email.patchValue(this.auth.signUpData.AllowReceiveEmails);
    }
    if (this.auth.signUpData.AllowReceiveSMS) {
      this.profileForm.controls.sms.patchValue(this.auth.signUpData.AllowReceiveSMS);
    }

    // get query params
    this.route.queryParamMap.subscribe(queryParams => {
      if (queryParams.get('goToRoute')) {
        this.goToRoute = queryParams.get('goToRoute');
      }
    });
  }

  openModal(content) {
    this.modal.modalOpen(content);
  }

  dismissModal() {
    this.modal.modalClose();
  }

  closeProfileModal() {
    this.auth.signUpData.Email = null;
    this.auth.signUpData.Password = null;
    this.auth.signUpData.FirstName = null;
    this.auth.signUpData.LastName = null;
    this.auth.signUpData.CellPhone = null;
    this.auth.signUpData.GenderID = null;
    this.auth.signUpData.DateOfBirth = null;
    this.auth.signUpData.birthday = null;
    this.auth.signUpData.AllowReceiveEmails = null;
    this.auth.signUpData.AllowReceiveSMS = null;
    this.modal.modalClose();
  }

  nextPage() {
    this.modal.modalClose();
    this.router.navigate(['/booking/preference']);
  }

  onSubmit() {
    this.submitted = true;

    if (this.profileForm.valid) {
      this.processing = true;
      this.auth.signUpData.FirstName = this.profileForm.controls.firstName.value;
      this.auth.signUpData.LastName = this.profileForm.controls.lastName.value;
      this.auth.signUpData.DateOfBirth = moment({
        year: 1990,
        month: this.birthMonths.indexOf(this.profileForm.controls.birthMonth.value),
        day: parseInt(this.profileForm.controls.birthDay.value, 10),
      }).format('YYYY-MM-DD');
      this.auth.signUpData.AllowReceiveEmails = this.profileForm.controls.email.value
        ? true
        : false;
      this.auth.signUpData.AllowReceiveSMS = this.profileForm.controls.sms.value ? true : false;
      this.auth.signUpData.GenderID = this.profileForm.controls.gender.value;

      this.auth.signup().subscribe(res => {
        if (res.IsSuccess) {
          this.toastr.success('Registration Successful');

          // make Login call to
          // auto login after signup
          this.auth
            .login(this.auth.signUpData.Email, this.auth.signUpData.Password)
            .subscribe(res2 => {
              if (res2.error == null) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                this.utils.setItem('currentUser', res);
                this.auth.setCurrentUserValue(res);
                this.auth.signUpData.Email = null;
                this.auth.signUpData.Password = null;
                this.auth.signUpData.FirstName = null;
                this.auth.signUpData.LastName = null;
                this.auth.signUpData.CellPhone = null;
                this.auth.signUpData.GenderID = null;
                this.auth.signUpData.DateOfBirth = null;
                this.auth.signUpData.birthday = null;
                this.auth.signUpData.AllowReceiveEmails = null;
                this.auth.signUpData.AllowReceiveSMS = null;

                // Make custom fields call with additional data
                // ---------------------------------------------------
                this.updateCustomFields(res2);
              } else {
                this.toastr.error('Invalid username or password');
              }
            });
          this.processing = false;
          this.dismissModal();
          this.router.navigate([this.goToRoute]);
        } else {
          this.processing = false;
          this.dismissModal();
          this.auth.signUpData.Email = this.email;
          this.auth.signUpData.Password = this.password;
          this.auth.signUpData.CellPhone = this.cellphone;
          this.modal.modalOpen(this.signup);

          this.utils.showAppError(res);
        }
      });
    }
  }

  updateCustomFields(currentUser) {
    const data = {
      CustomerFieldValues: {
        CustomerTypeID: 1,
        FieldValues: [
          {
            Key: environment.booker.customFields.staffGenderPreference,
            Value: {
              SelectionOptionValue: {
                Option: this.profileForm.controls.staffGenderPreference.value,
              },
            },
          },
          {
            Key: environment.booker.customFields.allergies,
            Value: {
              TextValue: {
                Value: this.profileForm.controls.allergies.value,
              },
            },
          },
        ],
        RepeatableFieldValues: null,
      },
      access_token: currentUser.access_token,
    };
    const customerId = currentUser.Customer.Customer.ID;
    this.auth.updateCustomerCustomFields(data, customerId).subscribe(
      res => {
        if (res.IsSuccess) {
        } else {
          this.utils.showAppError(res);
        }
      },
      err => {}
    );
  }

  get formControl() {
    return this.profileForm.controls;
  }
}
