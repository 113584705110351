import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root',
})
export class ModalManagementService {
  constructor(private modalService: NgbModal) {}

  modalClose() {
    this.modalService.dismissAll();
  }

  modalOpen(content) {
    return this.modalService
      .open(content, { centered: true, size: 'lg' })
      .result.then(result => {}, reason => {});
  }
}
