export const environment = {
  production: false,
  environment: 'dev',
  host: 'trilogyspaholdings.cloud',
  api: {
    domain: 'dev-api',
    port: 443,
  },
  booker: {
    customFields: {
      staffGenderPreference: 46313,
      staffGenderPreferenceValues: [
        { id: 528082, value: 'Staff Gender Preference' },
        { id: 528045, value: 'Preference does not matter' },
        { id: 528044, value: 'Male' },
        { id: 528043, value: 'Female' },
      ],
      allergies: 44,
    },
  },
};
