import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/internal/operators';
import { ToastrService } from 'ngx-toastr';
import { CartService } from './cart.service';
import { Observable, Subject } from 'rxjs/Rx';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ItineraryService } from '@app/core/services/itinerary.service';
import { EventEmitter } from 'events';

/*

// Constants, global functions
// ---------------------------------------------------
export class Utils {
    // static doSomething(val: string) {return val;}
}
*/

@Injectable({
  providedIn: 'root',
})
export class Utils implements OnInit {
  /*  =Constants
  -------------------------------------------------------------- */

  // Messages
  // ---------------------------------------------------
  messages: any = {
    auth: {
      loginError: 'Invalid username or password',
      autoLoginError: 'System encounter an issue, redirecting to search',
    },
    profile: {
      birthdaySuccess: 'Birthday updated successfully.',
      communicationPreferenceSuccess: 'Communication preference updated successfully.',
    },
    preference: {
      success: 'Preference saved successfully.',
      isGuestAlreadyAdded: 'This guest already exist, please choose it from the list.',
    },
    booking: {
      cancelled: 'Booking cancelled successfully.',
    },
  };

  // Custom Fields
  // ---------------------------------------------------
  customFields = {
    preference: {
      /*41: {
          type: "picklist",
          list: [{id: 1, value: "Male"}, {id: 2, value: "Female"}],
          key: 41,
          label: "staffGender"
      },*/
      46313: {
        type: 'picklist',
        list: [
          { id: 528082, value: 'Staff Gender Preference' },
          { id: 528045, value: 'Preference does not matter' },
          { id: 528044, value: 'Male' },
          { id: 528043, value: 'Female' },
        ],
        key: 46313,
        label: 'staffGender',
      },
      44: {
        type: 'input',
        key: 44,
        label: 'allergies',
      },

      46299: {
        type: 'picklist',
        list: [
          { id: 528077, value: 'No Preference' },
          { id: 528014, value: 'Fruit Infused Spring water' },
        ],
        key: 46299,
        label: 'water',
      },
      46300: {
        type: 'picklist',
        list: [
          { id: 528079, value: 'No Preference' },
          { id: 528015, value: 'Assortment of teas' },
          { id: 528016, value: 'Cappuccino' },
          { id: 528017, value: 'Champagne' },
          { id: 528018, value: 'Espresso' },
        ],
        key: 46300,
        label: 'additionalBeverage',
      },
      46288: {
        type: 'picklist',
        list: [
          { id: 528080, value: 'No Preference' },
          { id: 528019, value: 'American Songbook' },
          { id: 528020, value: 'Instrumental Rock' },
          { id: 528021, value: 'Motown Classics' },
          { id: 527939, value: 'Nature Sounds' },
          { id: 527940, value: 'Piano' },
          { id: 528022, value: 'Pop Music' },
          { id: 528023, value: 'Reggae' },
          { id: 527941, value: 'Smooth Jazz' },
          { id: 528024, value: 'Spa Music' },
          { id: 528025, value: 'Zen' },
        ],
        key: 46288,
        label: 'music',
      },

      46301: {
        type: 'picklist',
        list: [
          { id: 528026, value: 'L' },
          { id: 528027, value: 'M' },
          { id: 528028, value: 'S' },
          { id: 528029, value: 'XL' },
        ],
        key: 46301,
        label: 'sandalSize',
      },

      46302: {
        type: 'picklist',
        list: [
          { id: 528081, value: 'No Preference' },
          { id: 528030, value: 'Shirt Steam' },
          { id: 528031, value: 'Shoe Buff' },
        ],
        key: 46302,
        label: 'garmentTreatment',
      },
    },

    creditCard: {
      46303: {
        label: 'cardNumber',
        key: 46303,
      },
      46304: {
        label: 'nameOnCard',
        key: 46304,
      },
      46305: {
        label: 'expDate',
        key: 46305,
      },
      46306: {
        label: 'secCode',
        key: 46306,
      },
      46311: {
        label: 'cardType',
        key: 46311,
      },
    },
  };

  // Credit Card
  // ---------------------------------------------------
  creditCard = {
    allowedCards: ['visa', 'mastercard', 'discover', 'amex'],
  };

  // Misc
  // ---------------------------------------------------
  mapKey = 'AIzaSyDVXqTl_nfrOyqRiVohda6aetQMvJnCl2M';

  // End Constants

  // Other Variables
  // ---------------------------------------------------
  counterDuration = 10 * 60; // in seconds (600)
  countDown;
  counter;
  tick = 1000;

  onTimeSlotRemoved = new Subject<any>();
  onAutoLogin = new Subject<any>();

  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private ngxLoader: NgxUiLoaderService,
    private router: Router,
    private itineraryService: ItineraryService
  ) {}

  fireObservable() {
    this.onTimeSlotRemoved.next(true);
  }

  ngOnInit() {}

  setItem(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  getItem(key) {
    const data = localStorage.getItem(key);
    if (data) {
      return JSON.parse(localStorage.getItem(key));
    } else {
      return null;
    }
  }

  removeItem(key) {
    localStorage.removeItem(key);
  }

  // Counter
  setCounter() {
    this.setItem('counterSetTime', new Date());

    // set counter and run timer
    this.counter = this.counterDuration; // 10 minutes
    return (this.countDown = Observable.timer(0, this.tick)
      .take(this.counter)
      .map(() => {
        --this.counter;

        return this.counter;
      }));
  }

  checkCounter() {
    let counterSetTime = this.getItem('counterSetTime');
    let currentDateTime;
    let diff;
    let isCounterExpired;
    let remainingCounterTime = null;

    // counter exist
    // check its expiry
    if (counterSetTime) {
      counterSetTime = new Date(counterSetTime);
      currentDateTime = new Date();
      diff = (currentDateTime.getTime() - counterSetTime.getTime()) / 1000;
      diff = Math.round(diff);

      isCounterExpired = diff > this.counterDuration;
      remainingCounterTime = this.counterDuration - diff;

      if (remainingCounterTime > 0) {
        remainingCounterTime = remainingCounterTime;
      } else {
        this.resetLocalData();
      }
    }

    return remainingCounterTime;
  }

  getCounter() {
    const remainingCounterTime = this.checkCounter();

    if (!remainingCounterTime) {
      this.removeItem('counterSetTime');
      return null;
    }

    // return if exist
    // this condition works in no page referesh
    if (this.countDown) {
      return this.countDown;
    } else if (remainingCounterTime) {
      // check if on page referesh
      // set counter with remaining time

      this.counter = remainingCounterTime; // 10 minutes

      return (this.countDown = Observable.timer(0, this.tick)
        .take(this.counter)
        .map(() => {
          --this.counter;
          return this.counter;
        }));
    }
  }

  resetLocalData() {
    this.removeItem('cartList');
    this.removeItem('userList');
    this.removeItem('itineraryId');
    this.removeItem('counterSetTime');
  }

  resetAndBackToResult() {
    this.resetLocalData();
    this.router.navigate(['/']);
  }

  cancelItinerary() {
    const itineraryId = this.getItem('itineraryId');

    this.ngxLoader.start();
    this.itineraryService.deleteItinerary(itineraryId).subscribe(
      res => {
        if (res.IsSuccess) {
        } else {
          this.showAppError(res);
        }
        this.ngxLoader.stop();
        // in any case
        this.resetAndBackToResult();
      },
      err => {
        this.ngxLoader.stop();
        // in any case
        this.resetAndBackToResult();
      }
    );
  }

  showAppError(res) {
    // skip messages for invalid access token
    // as we are handling it in error interceptor
    // by silently loggin user in case of token expire or invalid

    if (!res.IsSuccess && res.ErrorCode === 1000) {
      return false;
    }

    if (res.ArgumentErrors && res.ArgumentErrors.length) {
      this.toastr.error(res.ArgumentErrors[0].ErrorMessage);
    } else {
      this.toastr.error(res.ErrorMessage);
    }
  }

  /*  =Remove item (timslot)
  -------------------------------------------------------------- */
  removeSlotItem(params) {
    let cartList = params.cartList,
      /* userList = params.userList,
       timeSlotIndex = params.timeSlotIndex,
       serviceItemIndex = params.serviceItemIndex,
       userItemIndex = params.userItemIndex,*/
      user = params.user,
      service = params.service,
      timeSlot = params.timeSlot,
      itineraryId = params.itineraryId;

    let cartItemIndex;
    let cartItem = cartList.find((cartItem, index) => {
      if (
        cartItem.treatmentId === service.treatmentId &&
        cartItem.timeSlotId === timeSlot.timeSlotId
      ) {
        cartItemIndex = index;
        return true;
      }
    });

    // extend params object with cartitem
    params.cartItem = cartItem;
    params.cartItemIndex = cartItemIndex;

    const dataDeleteItineraryitem = {
      ID: itineraryId,
      ItemIDs: [cartItem.itinerary.ItemID],
    };

    // only deleting item in the cart
    // delete the entire itinerary
    if (cartList.length === 1) {
      this.ngxLoader.start();
      this.itineraryService.deleteItinerary(itineraryId).subscribe(
        res => {
          if (res.IsSuccess) {
            this.removeCartItemFromLocal(params);
          } else {
            this.showAppError(res);
          }
          this.ngxLoader.stop();
        },
        err => {
          this.ngxLoader.stop();
        }
      );
    } else {
      // check if it is not first item
      // which is added with create itinerary service
      // we cannot directly delete this
      if (cartItem.itinerary.Guest.IsPrimaryPayer) {
        let anotherCartItem = cartList.find(item => !item.itinerary.Guest.IsPrimaryPayer);
        if (!anotherCartItem) {
          anotherCartItem = cartList.find(item => item.timeSlotId !== cartItem.timeSlotId);
        }

        /*const user2: any = {
          FirstName: user.FirstName,
          LastName: user.LastName,
          Email: user.Email,
          IsPrimaryPayer: true,
        };
        if (user.AllowReceiveEmails) {
          user2.AllowReceiveEmails = user.AllowReceiveEmails;
        }

        if (user.AllowReceiveSMS) {
          user2.AllowReceiveSMS = user.AllowReceiveSMS;
        }

        */
        const user2 = anotherCartItem.itinerary.Guest;
        user2.IsPrimaryPayer = true;

        const itineraryToUpdate = {
          ItineraryItems: [
            {
              ItemID: anotherCartItem.itinerary.ItemID,
              TreatmentID: anotherCartItem.treatmentId,
              IsStaffSelected: false,
              StartDateTime: '/Date(' + anotherCartItem.timestampStart + ')/',
              AppointmentNotes: null,
              Guest: user2,
            },
          ],
        };

        this.ngxLoader.start();
        this.itineraryService.updateItineraryItem(itineraryToUpdate, itineraryId).subscribe(
          res => {
            if (res.IsSuccess) {
              // update status changed
              this.itineraryService
                .deleteItineraryItem(dataDeleteItineraryitem, itineraryId)
                .subscribe(
                  res => {
                    if (res.IsSuccess) {
                      this.removeCartItemFromLocal(params);
                    } else {
                      this.showAppError(res);
                    }
                    this.ngxLoader.stop();
                  },
                  err => {
                    this.ngxLoader.stop();
                  }
                );
            } else {
              this.ngxLoader.stop();
              this.showAppError(res);
            }
          },
          error => {
            this.ngxLoader.stop();
          }
        );
      } else {
        this.ngxLoader.start();
        this.itineraryService.deleteItineraryItem(dataDeleteItineraryitem, itineraryId).subscribe(
          res => {
            if (res.IsSuccess) {
              this.removeCartItemFromLocal(params);
            } else {
              this.showAppError(res);
            }
            this.ngxLoader.stop();
          },
          err => {
            this.ngxLoader.stop();
          }
        );
      }
    }
  }

  removeCartItemFromLocal(params) {
    let cartList = params.cartList,
      userList = params.userList,
      timeSlotIndex = params.timeSlotIndex,
      serviceItemIndex = params.serviceItemIndex,
      userItemIndex = params.userItemIndex,
      user = params.user,
      service = params.service,
      cartItemIndex = params.cartItemIndex;

    // remove from cart and update cart in localStorage
    cartList.splice(cartItemIndex, 1);
    this.setItem('cartList', cartList);

    service.timeslots.splice(timeSlotIndex, 1);

    // delete service if no time slots
    if (!service.timeslots.length) {
      user.services.splice(serviceItemIndex, 1);
    }

    // delete user if no services
    if (!user.services.length) {
      userList.splice(userItemIndex, 1);
    }

    // no data then get back to results
    if (!userList.length) {
      this.resetAndBackToResult();
    }

    // update UserList as well
    this.setItem('userList', userList);

    // emit an event
    // to do something on remove
    this.onTimeSlotRemoved.next(true);
  }

  // check if it is mobile or not
  // ---------------------------------------------------
  checkMobile() {
    var check = false;
    (function(a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor);
    return check;
  }
}
