import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutResultComponent } from './layout-result/layout-result.component';
import { LayoutAccountComponent } from './layout-account/layout-account.component';

const routes: Routes = [
  {
    path: 'account',
    component: LayoutAccountComponent,
    loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
  },
  {
    path: '',
    component: LayoutResultComponent,
    children: [
      {
        path: 'auth',
        loadChildren: () => import('./onboarding/onboarding.module').then(m => m.OnboardingModule),
      },
      {
        path: 'booking',
        loadChildren: () => import('./booking/booking.module').then(m => m.BookingModule),
      },
      {
        path: '',
        loadChildren: () => import('./results/results.module').then(m => m.ResultsModule),
      },

      {
        path: '404',
        loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule),
        pathMatch: 'full',
      },
      {
        path: '404/',
        loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule),
        pathMatch: 'full',
      },
      {
        path: '**',
        loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
