import { Injectable } from '@angular/core';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import { HttpClient } from '@angular/common/http';
import { SessionService } from './session.service';
import { CookieJar } from '@app/shared/cookie-jar';

@Injectable()
export class StartupService {
  private _startupData: any;

  constructor(
    private http: HttpClient,
    private sessionService: SessionService,
    private cookieJar: CookieJar
  ) {}

  // This is the method you want to call at bootstrap
  // Important: It should return a Promise
  load(): Promise<any> {
    this._startupData = null;

    const sessionToken = this.cookieJar.get('sessionToken');

    if (!sessionToken) {
      return this.sessionService
        .hitSession()
        .toPromise()
        .then((data: any) => (this._startupData = data))
        .catch((err: any) => Promise.resolve());
    } else {
      this._startupData = 'data';
    }
  }

  get startupData(): any {
    return this._startupData;
  }
}
