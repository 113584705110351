import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@app/core/services/auth.service';
import { Utils } from '@app/core/services/utils.service';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-layout-account',
  templateUrl: './layout-account.component.html',
  styleUrls: ['./layout-account.component.scss'],
})
export class LayoutAccountComponent implements OnInit, OnDestroy {
  usernameDisplay: string = null;

  showMenuMobile: boolean = false;
  isLoggedIn: boolean = false;

  currentUrl: any;
  routerSubscription;

  constructor(
    private auth: AuthService,
    private utils: Utils,
    private ngxLoader: NgxUiLoaderService,
    private router: Router
  ) {
    this.routerSubscription = this.router.events.subscribe((event: any) => {
      setTimeout(() => {
        this.currentUrl = this.router.url;
      }, 100);
    });
  }

  ngOnInit() {
    this.auth.currentUser.subscribe(value => {
      if (value) {
        this.isLoggedIn = true;
        if (value.Customer) {
          this.usernameDisplay =
            value.Customer.Customer.FirstName.charAt(0) +
            value.Customer.Customer.LastName.charAt(0);
        }
      } else {
        // if page is refreshed and currentUser observers value is null get currentUser from local storage
        const currentUser = this.utils.getItem('currentUser');

        if (currentUser) {
          this.isLoggedIn = true;
          this.usernameDisplay =
            currentUser.Customer.Customer.FirstName.charAt(0) +
            currentUser.Customer.Customer.LastName.charAt(0);
        } else {
          // if currentUser observers value is null and there is no currentUser in Localstorage
          this.isLoggedIn = false;
          this.usernameDisplay = null;
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  toggleMenuMobile() {
    this.showMenuMobile = !this.showMenuMobile;
  }

  closeMenu() {
    this.showMenuMobile = false;
  }

  logout() {
    this.showMenuMobile = false;
    this.ngxLoader.start();

    this.auth.logout().subscribe(res => {
      if (res.IsSuccess) {
        this.utils.removeItem('currentUser');

        if (this.currentUrl !== '/') {
          this.utils.resetLocalData();
        }

        this.auth.setCurrentUserNull();
        this.router.navigate(['/']);
        this.ngxLoader.stop();
      } else {
        this.utils.removeItem('currentUser');
        this.auth.setCurrentUserNull();
        this.ngxLoader.stop();
        this.router.navigate(['/']);
      }
    });
  }
}
