import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ItineraryService {


  constructor(private http: HttpClient) {
  }


  // Create Itinerary
  createItinerary(data) {
    const url = '/itineraries';
    const options = {withCredentials: true};
    return this.http.post<any>(url, data, options);
  }

  addItineraryItem(data, itineraryID) {
    const url = `/itineraries/${itineraryID}/items`;
    const options = {withCredentials: true};
    return this.http.post<any>(url, data, options);
  }

  bookItinerary(data, itineraryID) {
    const url = `/itineraries/${itineraryID}/book`;
    const options = {withCredentials: true};
    return this.http.post<any>(url, data, options);
  }

  deleteItinerary(itineraryID) {
    const url = `/itineraries/${itineraryID}`;
    const options = {withCredentials: true};
    return this.http.delete<any>(url, options);
  }

  deleteItineraryItem(data, itineraryID) {
    const url = `/itineraries/${itineraryID}/items`;
    const options = {withCredentials: true, body: data};
    return this.http.delete<any>(url, options);
  }

  // Update Itinerary
  updateItineraryItem(data, itineraryID) {
    const url = `/itineraries/${itineraryID}/items`;
    const options = {withCredentials: true};
    return this.http.put<any>(url, data, options);
  }

  // Addon services
  // ---------------------------------------------------
  addAddonToItinerary(data, itineraryID) {
    const url = `/itineraries/${itineraryID}/addons`;
    const options = {withCredentials: true};
    return this.http.post<any>(url, data, options);
  }

  updateItineraryAddon(data) {
    const url = `/itineraries/update_addons`;
    const options = {withCredentials: true};
    return this.http.put<any>(url, data, options);
  }

  // offer Code
  // ---------------------------------------------------
  applyCode(data) {
    const url = `/itineraries/add_specials`;
    const options = {withCredentials: true};
    return this.http.put<any>(url, data, options);
  }


}
