import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Daterangepicker } from 'ng2-daterangepicker';

import { CookieJarModule } from './cookie-jar';
// Pipes
import { FilterListPipe } from './pipes/filter-list.pipe';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import {ToastrModule} from "ngx-toastr";
import {FormatTimePipe} from "./pipes/format-time.pipe";
import {SafePipe} from "@app/shared/pipes/safe.pipe";

// import {ControlMessagesComponeng nt} from './component/control-messages/control-messages.component';

// import {SpinnerComponent} from './component/spinner/spinner.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    CookieJarModule,
    Daterangepicker,
    ToastrModule.forRoot({
        maxOpened: 1,
        autoDismiss: true
    }), // ToastrModule added
  ],
  declarations: [
    FilterListPipe,
    FormatTimePipe,
    SafePipe,
    ClickOutsideDirective,
    // ControlMessagesComponent,
    // SpinnerComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    Daterangepicker,
    FilterListPipe,
    FormatTimePipe,
    SafePipe,
    CookieJarModule,
    ClickOutsideDirective,
    // ControlMessagesComponent,
    // SpinnerComponent
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      // providers: [SomeService1, SomeService2]
    };
  }
}
