import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { SharedModule } from '../shared/shared.module';
import { OnboardingRoutingModule } from './onboarding-routing.module';
import { SignupComponent } from './signup/signup.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ProfileComponent } from './profile/profile.component';
import { LogoutComponent } from './logout/logout.component';

@NgModule({
  declarations: [LoginComponent, SignupComponent, ForgotPasswordComponent, ProfileComponent, LogoutComponent],
  imports: [
    CommonModule,
    OnboardingRoutingModule,
    SharedModule
  ],
  exports: [LoginComponent, SignupComponent, ForgotPasswordComponent, ProfileComponent],
})
export class OnboardingModule { }
