import { Injectable } from '@angular/core';

@Injectable()
export class CookieJar {
  /**
   * @constructor
   */
  constructor() {}

  /**
   * @function
   * @name get
   * @param {string} name - name of cookie to retriev
   * @return {string}
   */
  public get(name: string): string {
    if (!!document.cookie) {
      const ca: string[] = document.cookie.split(';');
      const caLen: number = ca.length;
      const cookieName = name + '=';
      let c: string;
      for (let i = 0; i < caLen; i += 1) {
        c = ca[i].replace(/^\s\+/g, '').trim();
        if (c.indexOf(cookieName) >= 0) {
          let cookieValue = c.substring(cookieName.length, c.length);
          if (cookieValue[0] === '=') {
            cookieValue = c.substring(cookieName.length + 1, c.length);
          }
          return cookieValue;
        }
      }
    }
    return null;
  }

  /**
   * @function
   * @name has
   * @param {string} name - name of cookie to see if it exists
   * @returns {boolean}
   */
  public has(name: string): boolean {
    return this.get(name) !== null;
  }

  /**
   * @function
   * @name delete
   * @param {string} name - name of cookie to remove
   */
  public delete(name: string, domain: string = null): void {
    this.remove(name, '/', domain);
  }

  /**
   * @function
   * @name remove
   * @param {string} name - name of cookie to remove
   */
  public remove(name: string, path: string = null, domain: string = null): void {
    this.set(name, '', -1 * 365 * 60 * 60 * 24, path, domain); // 1 year
  }

  /**
   * @function
   * @name set
   * @param {string} name - name of cookie to set value to
   * @param {string} value - value to store to cookie
   * @param {string|number|date} end - number of seconds until cookie expires
   * @param {string} [path = null] - site path to store the cookie to
   * @param {string} [domain = null] - domain to store the cookie to
   */
  public set(
    name: string,
    value: string,
    end: any,
    path: string = null,
    domain: string = null
  ): void {
    const d: Date = new Date();
    let expires = '';
    switch (end.constructor) {
      case Number:
        if (end === Infinity) {
          expires = 'expires=Fri, 31 Dec 9999 23:59:59 GMT';
        } else {
          d.setTime(d.getTime() + end * 1000);
          expires = 'expires=' + d.toUTCString();
        }
        break;
      case String:
        if (end !== 'SESSION') {
          expires = 'expires=' + end;
        }
        break;
      case Date:
        expires = 'expires=' + end.toUTCString();
        break;
      default:
        break;
    }
    const cookie: string[] = [name + '=' + value];
    if (expires) {
      cookie.push(expires);
    }
    if (domain) {
      cookie.push('domain=' + domain);
    }
    if (path) {
      cookie.push('path=' + path);
    }
    document.cookie = cookie.join('; ');
  }
}
