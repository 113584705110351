import { Component, OnInit } from '@angular/core';
import { ModalManagementService } from '@app/core/services/modal-management.service';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {



  constructor(private modal: ModalManagementService) { }

  ngOnInit() {
  }

  dismissModal() {
    this.modal.modalClose();
  }

}
