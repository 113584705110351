import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs/index';
import { User, GuestSignUp } from '../models/user';
import { Utils } from './utils.service';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  signUpData: any = {
    Email: null,
    Password: null,
    FirstName: null,
    LastName: null,
    CellPhone: null,
    GenderID: null,
    DateOfBirth: null,
    birthday: null,
    AllowReceiveEmails: null,
    AllowReceiveSMS: null,
  };

  private ssk = 'ygolirt 2019 aps ';
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  user: any;

  constructor(private http: HttpClient, private utils: Utils) {
    this.currentUserSubject = new BehaviorSubject<any>(null);
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  // user login
  login(Email, Password) {
    const options = { withCredentials: true };
    return this.http.post<any>('/customers/login', { Email, Password }, options);
  }

  // user logout
  logout() {
    const options = { withCredentials: true };
    const url = '/customers/logout';
    const accessToken = this.utils.getItem('currentUser').access_token;
    const logoutBody = {
      access_token: accessToken,
    };
    return this.http.post<any>(url, logoutBody, options);
  }

  setCurrentUserNull() {
    this.currentUserSubject.next(null);
  }

  setCurrentUserValue(res) {
    this.currentUserSubject.next(res);
  }

  // user signup
  signup() {
    this.signUpData.birthday = this.signUpData.DateOfBirth;
    this.signUpData.DateOfBirth = '/Date(' + this.signUpData.birthday + ')/';
    const url = '/customers';

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const options = { headers: headers, withCredentials: true };
    return this.http.patch<any>(url, this.signUpData, options);
  }

  // Guest Signup
  guestSignup(signup: GuestSignUp) {
    const url = '/customers/119699920';

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': '{{bkr_ocp_subscription_key_primary}}',
    });

    const options = { headers: headers };

    return this.http.patch<any>(url, signup, options).pipe(
      map(data => {
        return {
          ArgumentErrors: null,
          ErrorCode: 0,
          ErrorMessage: null,
          IsSuccess: true,
          CustomerID: 119700211,
        };
      })
    );
  }

  registerGuestCustomer(data) {
    const url = '/customers';
    const options = { withCredentials: true };
    return this.http.post<any>(url, data, options);
  }

  getCustomerDetails() {
    const currentUser = this.utils.getItem('currentUser');
    const customerId = currentUser.Customer.Customer.ID;
    const url = `/customers/${customerId}?access_token=${currentUser.access_token}`;
    const options = { withCredentials: true };
    return this.http.get<any>(url, options);
  }

  updateCustomer(data, customerId) {
    const url = `/customers/${customerId}`;
    const options = { withCredentials: true };
    return this.http.put<any>(url, data, options);
  }

  updateCustomerCustomFields(data, customerId) {
    const url = `/customers/${customerId}/custom_fields`;
    const options = { withCredentials: true };
    return this.http.put<any>(url, data, options);
  }

  saveUid(textToConvert, email) {
    const ssk = this.ssk + email;
    const uid = CryptoJS.AES.encrypt(textToConvert.trim(), ssk).toString();
    this.utils.setItem('uid', uid);
  }

  getUid() {
    const textToConvert = this.utils.getItem('uid');
    const currentUser = this.utils.getItem('currentUser');

    let email, ssk, uid;

    if (textToConvert && currentUser && currentUser.Customer.Customer.Email) {
      email = currentUser.Customer.Customer.Email;
      ssk = this.ssk + email;
      uid = CryptoJS.AES.decrypt(textToConvert.trim(), ssk).toString(CryptoJS.enc.Utf8);
    }

    if (uid) {
      return {
        uid: uid,
        email: email,
      };
    }

    return null;
  }

  // Merchants
  // ---------------------------------------------------

  startMerchantSession() {
    const url = '/merchants/token';
    const options = { withCredentials: true };
    return this.http.get<any>(url, options);
  }

  findCustomerInMerchant(data) {
    const url = '/merchants/search/customer';
    const options = { withCredentials: true };
    return this.http.post<any>(url, data, options);
  }
}
