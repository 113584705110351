import { Component, OnInit, ViewChild, TemplateRef, Input } from '@angular/core';
import { ModalManagementService } from '@app/core/services/modal-management.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import { Utils } from '@app/core/services/utils.service';
import { Email, Password } from '@namespaces/validators';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  @ViewChild('profile', { static: false }) profile: TemplateRef<any>;

  signupForm: FormGroup;
  submitted = false;
  passwordToggle = false;
  currentRoute: string = this.router.url;

  processing = false;
  @Input() goToRoute = '/';

  constructor(
    private modal: ModalManagementService,
    private formBuilder: FormBuilder,
    private router: Router,
    private utils: Utils,
    private route: ActivatedRoute,
    private auth: AuthService
  ) {}

  ngOnInit() {
    this.signupForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(Email.Pattern)]],
      phone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      password: [
        '',
        [
          Validators.required,
          Validators.pattern(Password.Pattern),
          Validators.minLength(8),
          Validators.maxLength(25),
        ],
      ],
    });

    if (this.auth.signUpData.Email) {
      this.signupForm.controls.email.patchValue(this.auth.signUpData.Email);
    }

    if (this.auth.signUpData.CellPhone) {
      this.signupForm.controls.phone.patchValue(this.auth.signUpData.CellPhone);
    }

    if (this.auth.signUpData.Password) {
      this.signupForm.controls.password.patchValue(this.auth.signUpData.Password);
    }

    // get query params
    this.route.queryParamMap.subscribe(queryParams => {
      if (queryParams.get('goToRoute')) {
        this.goToRoute = queryParams.get('goToRoute');
      }
    });
  }

  dismissModal() {
    this.modal.modalClose();
  }

  closeSignupModal() {
    this.auth.signUpData.Email = null;
    this.auth.signUpData.Password = null;
    this.auth.signUpData.FirstName = null;
    this.auth.signUpData.LastName = null;
    this.auth.signUpData.CellPhone = null;
    this.auth.signUpData.GenderID = null;
    this.auth.signUpData.DateOfBirth = null;
    this.auth.signUpData.birthday = null;
    this.auth.signUpData.AllowReceiveEmails = null;
    this.auth.signUpData.AllowReceiveSMS = null;
    this.modal.modalClose();
  }

  openModal(content) {
    this.dismissModal();
    this.modal.modalOpen(content);
  }

  get signupControl() {
    return this.signupForm.controls;
  }

  showPassword() {
    this.passwordToggle = !this.passwordToggle;
  }

  onSubmit() {
    this.submitted = true;
    if (this.signupForm.valid) {
      this.processing = true;
      this.auth.signUpData.Email = this.signupForm.controls.email.value;
      this.auth.signUpData.CellPhone = this.signupForm.controls.phone.value;
      this.auth.signUpData.Password = this.signupForm.controls.password.value;
      this.submitted = false;
      this.processing = false;
      this.dismissModal();

      if (this.utils.checkMobile()) {
        this.router.navigate(['/auth/profile'], { queryParams: { goToRoute: this.goToRoute } });
      } else {
        this.modal.modalOpen(this.profile);
      }
    }
  }
}
